body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-title{
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .hero-title  {
    top: 40%;
    text-align: center;
  }
  .hero-title h1 {
    font-size: 6rem;
  }

  .hero-title h2 {
    font-size: 3rem;
  }
}
.ice-cream-wrapper {
  height:500px;
}


/*Review widget styling*/

.review-widget {
  min-height:100px;
}

.grw-net-widget .grw-net-text-bold {
  font-size: 4rem;
}

.grw-net-widget-one-type-3 .grw-net-logo-l {
  display: none !important;
}

.branding {
  display: none !important;
  opacity: 0 !important;
}

.grw-net-widget-four {
  background-color: transparent !important;
  border: none !important;
}

.byline-text {
  color: #000;
  font-family: "Mogra", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  margin-top: -2rem;
  text-align: center;
}

.bounce {
  text-align:center;
  margin:0 auto;
  height:50px;
  width:fit-content;
  padding:0 1rem 0 1rem;  
  border: 3px solid #000;
  color: #000;
  font-family: "Mogra", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  white-space: nowrap;
  -webkit-animation: bounce 1.75s infinite;
  cursor: pointer;
  }
